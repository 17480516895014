.vehicleMainImageWrapper {
  text-align: center;
}

.vehicleMainImage {
  max-width: 100%;
  max-height: 450px;
}

@media screen and (max-width: 750px) {
  .heading,
  .status {
    display: block;
  }

  .status {
    font-size: 16px;
  }

  .buttons {
    text-align: left !important;
    margin-bottom: 10px;
  }
}
