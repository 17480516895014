@import "src/assets/styles/variables";

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0;
  padding: 40px 30px 30px;
  border-radius: 15px;
  background-color: #ffffff;

  @media screen and (min-width: 600px) {
    margin-top: 125px;
  }
}

.headerWrapper {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  vertical-align: middle;

  @media screen and (min-width: 600px) {
    text-align: left;
  }
}

.avatarWrapper {
  text-align: center;
}

@media screen and (max-width: 599px) {
  .authCard {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 600px) {
  .avatarWrapper {
    display: inline-block;
    vertical-align: middle;
  }

  .headerText {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px !important;
  }

  .registerAction {
    text-align: right;
  }
}

.avatar {
  margin: 8px auto;
  background-color: $color-primary !important;
}

.form {
  width: 100%;
  margin-top: 8px;
}

.submitButton {
  margin: 15px 0 !important;
  background-color: $color-primary !important;
}

.backButton {
  margin: 15px 0 !important;
}

.additionalActions {
  margin-top: 10px;
}

.accentImage {
  position: absolute;
  right: 40px;
  top: -85px;
  width: 250px;
  height: 175px;
  background: url('../../../assets/images/car-out-of-shipping.png') no-repeat center;
  background-size: contain;

  @media screen and (max-width: 599px) {
    display: none;
  }
}
