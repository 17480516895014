.selectionCard {
  border: 1px solid #c4c4c4 !important;
}

.filterInput {
  padding: 5px 20px 10px !important;
}

.list {
  height: 270px !important;
  overflow-y: scroll !important;
}
