@import "src/assets/styles/variables";

.Heading {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100px;
  margin: 250px 0 20px;
}

.HeadingImage {
  width: 100%;
  max-width: 1000px;
}

.Subtitle {
  font-size: 26px !important;
  color: #ffffff;
  text-shadow: 2px 2px 10px #333a44;
}

.VimButton {
  margin-top: 30px !important;
  padding: 15px 35px !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($color-primary, 10%) !important;
  }
}

.Container {
  text-align: center;

  @media screen and (max-width: 1399px) {
    .Heading {
      margin-top: 175px;
    }
  }

  @media screen and (max-width: 599px) {
    .Subtitle {
      margin-bottom: 30px;
      font-size: 20px !important;
    }
  }
}

// Used in containers list page
.createButton {
  height: 100%;
}

.heading {
  padding: 20px;
}

.paginationContainer {
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: space-between; /* Space between Pagination and Items per page */
}

.itemsPerPageContainer {
  display: flex;
  align-items: center; /* Align label and selector vertically */
  gap: 8px; /* Optional: Add spacing between label and selector */
}

