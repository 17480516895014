.Header {
  background-color: transparent !important;
  box-shadow: none !important;
}

.HeaderLink {
  margin-right: 20px;
}

.Logo {
  max-height: 50px;
}

.HeaderWrapper {
  display: flex;
  align-items: center;
}

.Navigation {
  display: flex;
  width: 100%;
  padding: 25px 0;
  justify-content: space-between;

  .NavSection {
    display: flex;
  }
}

.Drawer {
  min-width: 200px;
}

.desktopHeader {
  background-color: rgba(#000000, 0.5);
}
