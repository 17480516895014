.active {
  background-color: #89e07f !important;
}

.inactive {
  background-color: #e0e0e0 !important;
}

.actionButton {
  width: 105px;
  color: #ffffff;
  text-align: center;

  &.activate {
    background-color: #4ad95d;
  }

  &.deactivate {
    background-color: #ff3a31;
  }
}

.roleAdmin {
  background-color: #faff62 !important;
}

.roleUser {
  background-color: #7de76d !important;
}

.roleWarehouse {
  background-color: #6ac4f3 !important;
}
