.wrapper {
  position: relative;

  @media screen and (max-width: 599px) {
    overflow-y: scroll;
  };
}

.stretch {
  width: 100%;
  max-width: 400px;
}

.singleColumn {
  display: block;
}

.twoColumns {
  display: none;
}

@media screen and (min-width: 600px) and (max-height: 750px) {
  .stretch {
    max-width: 700px;
  }

  .singleColumn {
    display: none;
  }

  .twoColumns {
    display: block;
  }
}

.accentImage {
  position: absolute;
  top: -65px;
  right: 20px;
  width: 200px;
  height: 100px;
  background: url('../../assets/images/car-shipping.png') no-repeat center;
  background-size: contain;

  @media screen and (max-width: 599px) {
    display: none;
  }
}
