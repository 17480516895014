// QRScannedPage.module.scss

.form {
    display: flex;
    flex-direction: column;
    max-width: 800px; /* Adjust the max-width as needed */
    margin: auto;
    margin-top: 50px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px; /* Adjust the font size */
    letter-spacing: 1px; /* Add letter spacing */
    font-weight: bold; /* Make the text bold */
    /* Add any additional styles based on your preferences */
  }
  
  .buttonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .qrReader {
    margin-top: 10px;
  }
  
  .vinListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .vinInfoContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .widerTextField {
    flex-grow: 1; /* Allow the TextField to grow and take available space */
  }
  
  /* QRScannedPage.module.scss */

.modalContainer {
  max-width: 600px;
  width:100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
}

.modalTitle {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.modalTextField {
  width: 100%;
  margin-bottom: 15px;
}

.modalButtonContainer {
  display: flex;
  justify-content: space-between;
}
