.dialog {
  max-width: none !important;
  overflow: visible !important;
}

.singleColumn {
  display: none;
}

.twoColumns {
  display: block;
}
