.Layout {
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(at 10% 30%, rgba(#124c72, 0.3) 0, rgba(#211611, 0.6) 100%),
    url('../../assets/images/main-background.jpg') fixed center;
  background-size: cover;
}

.Main {
  max-width: calc(100% - 30px);
  margin: 0 auto;
  padding-top: 100px;

  @media screen and (max-width: 599px) {
    padding-top: 75px;
  }

  @media screen and (min-width: 1500px) {
    max-width: 1400px;
  }
}
