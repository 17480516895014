.vehicleMainImageWrapper {
  text-align: center;
}

.vehicleMainImage {
  max-width: 100%;
  max-height: 450px;
}

.imageLoaderImage {
  width: 50%;
  height: 50%;
}
