.documentRow {
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 30px;
}

.imagePreview {
  height: 80px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 80%; 
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


#qr-code {
  margin-bottom: 20px;
}

.modalButtonContainer {
  display: flex;
  justify-content: space-between; /* This will evenly space the buttons */
  margin-top: 20px; /* Adjust the margin as needed */
}

.modalButtonContainer Button {
  margin: 0 50px; /* Add margin to each button */
}

.checkboxContainer {
  display: flex;
  flex-direction: row; /* Display checkboxes in a column */
}

.checkboxContainer label {
  display: block;
  font-weight: bold;
  font-size: 30px; /* Adjust the font size as needed */
  margin-bottom: 8px; /* Adjust spacing between checkboxes */
  margin-right: 30px;
  margin-top:20px;
}

.checkboxContainer input[type="checkbox"] {
  transform: scale(5); /* Increase the checkbox size */
  margin-right: 8px; /* Adjust spacing between checkbox and label */
}

.infoContainer {
  font-weight: bold;
  font-size: 40px; /* Adjust the font size as needed */
  margin-bottom: 8px; /* Adjust spacing between info sections */
}

.destination {
  color: red;
  font-size: 120px !important;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.qrCodeInfo {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Add spacing between columns */
}

@media screen and (min-width: 1200px) {
  .searchField {
    width: 358px;
  }
}


@media print {
  body > * {
    display: none;
  }
  .modalContent,
  .modalContent * {
    visibility: visible;
  }
  .modalContent {
    position: absolute;
    left: 10%;
    top: 0;
  }

  .modalButtonContainer {
    display: none !important;
  }
}


